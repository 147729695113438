<template>
  <v-container fluid>
    <v-layout>
      <v-flex>
        <page-title text="Ordini"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-layout row wrap>
            <v-flex xs12 sm8>
              <v-text-field
                light
                v-model="search"
                prepend-icon="search"
                @click:prepend="getOrdini"
                append-icon="cancel"
                @click:append="resetItems"
                @keyup.enter="changePage(1)"
                name="cerca"
                placeholder="Cerca" />
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                :items="stati"
                @change="changePage(1)"
                item-text="nome"
                item-value="id"
                v-model="stato"
                class="ml-4"
                label="Filtra per stato" />
            </v-flex>
          </v-layout>
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!ordini.length">
          Nessun ordine trovato
        </v-alert>
        <v-list class="mt-2" three-line v-if="ordini.length">
          <template v-for="(ordine, index) in ordini">
            <v-list-tile
              @click="modifica(ordine)"
              avatar
              :key="'tile-'+ordine._id">
              <v-list-tile-avatar>
                <v-avatar>
                  <semaphore :stato="ordine.stato" />
                </v-avatar>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title class="text-capitalize">
                  {{ordine.opera}}
                  <span v-if="ordine.numero">- N. {{ordine.numero}}</span>
                </v-list-tile-title>
                <v-list-tile-sub-title v-text="ordine.prodotto" />
                <v-list-tile-sub-title class="text--primary" v-text="ordine.descrizione" />
              </v-list-tile-content>
              <v-list-tile-action>
                <v-list-tile-action-text class="text--primary">{{ordine.ragione_sociale}}</v-list-tile-action-text>
                <v-list-tile-action-text class="text--primary">{{ordine.codice_finito}}</v-list-tile-action-text>
                <v-list-tile-action-text class="text--primary">{{ordine.consegna_da}}</v-list-tile-action-text>
              </v-list-tile-action>
            </v-list-tile>
            <v-divider :key="ordine._id" v-if="index < ordini.length - 1" inset />
          </template>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
      <v-tooltip left>
        <v-btn slot="activator" color="pink darken-1" dark fab fixed right bottom @click="$router.push({ name: 'ordine', params: { id: 'add' } })">
          <v-icon>add</v-icon>
        </v-btn>
        <span>Nuovo ordine</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import Semaphore from '@/components/common/Semaphore'

export default {
  data () {
    return {
      stati: [{ id: null, nome: '-- Qualsiasi --' }, { id: 0, nome: 'Rosso' }, { id: 1, nome: 'Giallo' }, { id: 2, nome: 'Verde' }],
      stato: null,
      ordini: [],
      ordine: {},
      search: null,
      pages: 0,
      page: 1,
      paginate: 20
    }
  },
  components: {
    PageTitle,
    Semaphore
  },
  methods: {
    getOrdini () {
      this.$plsqlReadAll('ordine', {
        what: this.search,
        stato: this.stato,
        page: this.page,
        rows4page: this.paginate
      })
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.ordini = response.data.items
            this.pages = response.data.pages
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    modifica (ordine) {
      /* if (!ordine.non_spedito) $EventBus.$emit('message', { type: 'error', text: 'Ordine spedito: modifiche disabilitate' })
      else this.$router.push({ name: 'ordine', params: { id: ordine._id } }) */
      this.$router.push({ name: 'ordine', params: { id: ordine._id } })
    },
    changePage (page) {
      this.page = page
      this.getOrdini()
    }
  },
  mounted () {
    this.getOrdini()
  }
}
</script>
