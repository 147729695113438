<template>
  <v-icon :stato="stato" :color="color" large>lens</v-icon>
</template>
<script>
export default {
  props: {
    stato: {
      type: Number,
      required: true,
      validator: (v) => {
        return [0, 1, 2].indexOf(v) !== -1
      }
    }
  },
  computed: {
    color () {
      if (!this.stato) {
        return 'red'
      } else if (this.stato === 1) {
        return 'accent'
      } else {
        return 'green'
      }
    }
  }
}
</script>
